


/************************** VARIABLES */

$dark-blue: #2e424a;

$pink: #ff957a;



/************************** SITEWIDE & UTILITY */

* { margin: 0; padding: 0; box-sizing: border-box; font-family: 'Glegoo', sans-serif; }

.desktopHide { display: none; }

a { 
    text-decoration: none; 
    color: $pink; 
    transition: all ease-in .2s; 

    &:hover { color: #fff; }
}

body { background-color: $dark-blue; color: #fff; }

h1, h2, h2 a, h3, h4 { 
    font-family: 'Staatliches', sans-serif; 
    font-weight: 400;
    color: white;
    text-shadow: 0 2px 6px rgba(0,0,0, .5);
}

p, .webBox, .articleBox, .col-1-3, .col-2-3 { 
    background-color: rgba(60,80,90, .8); 
    border-radius: 5px;
    padding: 20px;
    display: inline-block;
    max-width: 750px;
    font-size: 1.1rem;
    line-height: 1.9rem;
    box-shadow: 0 2px 6px rgba(0,0,0, .1);
}

code { 
    padding: 5px 5px; 
    border-radius: 3px; 
    background-color: rgba(0,0,0, .3);
    font-family: monospace;
    transition: all ease-in .2s;

    &:hover { background-color: rgba(0,0,0, .2); }
}

h1 { 
    font-size: 5rem; 
    color: $pink; 
    margin-bottom: 60px; 
    transform: rotate(-6deg);
    display: inline-block;
}

h2, h2 a { font-size: 3rem; line-height: 2.5rem; }

h3 { font-size: 2rem; line-height: 2rem; }

h4 { font-size: 1.6rem; }

.nav h2 { 
    padding: 10px; 
    background-color: rgba(0,0,0, .1);
    border-radius: 5px;
    transition: all ease-in .2s;
    margin-bottom: 20px;

    &:hover { transform: scale(1.05); }
}

.nav {
    transition: all ease-in .2s;
    text-align: center;
    height: 100vh;
    position: fixed;
    width: 20%;
    background: linear-gradient(-135deg, $pink 0%, #d36565 100%); 
    color: white;
    text-shadow: 0 2px 6px rgba(0,0,0, .5);
    box-shadow: 0 2px 6px rgba(0,0,0, .5);
    padding: 50px 3%;

    li { 
        font-size: 1.5rem;
        list-style: none; 
        margin-top: .5vh; 
        transition: ease-in .2s;
        transform-origin: 50% 50%;
        cursor: pointer;
        padding: 1vh;
        
        &:hover { transform: rotate(8deg); }
    }

    a { color: #fff; }
}

.hamburger { display: none; }

.container {
    margin-left: 20%;
    width: 80%;
}

.footer { 
    padding: 20px; 
    width: 100%;
    background-color: rgba(0,0,0, .3);
    color: rgba(255,255,255, .7);
    font-size: 1rem;
}

.container, .nav { 
    display: inline-block; 
    vertical-align: top; 
}

.content { min-height: 100vh;}

.pageBg { 
    padding: 60px 3%; 
    background-position: top right; 
    background-repeat: no-repeat; 
    background-attachment: fixed;
    min-height: 100vh;
}

.col-1-3, .col-2-3 { margin-right: 1%; margin-top: 1%; }

.col-1-3 { 
    width: 32%;
    
    h4 { text-align: center; }
}

.col-2-3 { width: 65%; }

.pinkTxt { color: $pink; }

.flexCtr { display: flex; align-items: center; }



/************************** WEB */

.rightScroll { 
    overflow-x: auto;
    overflow-y: hidden; 
    white-space: nowrap; 
    padding: 1rem;
    border-radius: 5px;
    box-shadow: inset 0 2px 6px rgba(0,0,0, .3);
    margin-top: 2rem;

    &::-webkit-scrollbar { height: 15px; }
  
    &::-webkit-scrollbar-track { 
        border-radius: 5px;
        background-color: rgba(0,0,0, .1);
    }
    
    &::-webkit-scrollbar-thumb {
        background-color: rgba(255,255,255, .2);
        border-radius: 5px;
    }

    &::-webkit-scrollbar-thumb:hover { background: rgba(255,255,255, .3); }
}

.webBox {
    max-width: 500px;
    padding: 20px;
    transition: all ease-in .2s;
    filter: brightness(90%);
    min-height: 600px;
    white-space: normal;
    margin-right: 1rem;
    vertical-align: top;

    &:last-of-type { margin-right: 0; }

    &:hover { 
        filter: brightness(100%); 
        transform: translateY(-5px); 
        box-shadow: 0 2px 6px rgba(0,0,0, .2); 
    }

    h3, a { text-align: center; margin: 10px; display: block; }

    a { position: absolute; bottom: 0; left: 0; right: 0; }
}

.webBox a, .articleBox a { font-weight: 700; font-size: 1.6rem; padding: 2rem; }

.webBoxImg { 
    border-radius: 5px; 
    background-size: cover; 
    height: 250px; 
    background-position: center top;
    width: 100%;
    box-shadow: 0 2px 6px rgba(0,0,0, .5);
}



/************************** DESIGN */

.mosaic { 
    columns: 3 300px; 
    column-gap: 1rem; 
    margin-top: 2rem; 

    img { 
        max-width: 100%; 
        filter: brightness(90%);
        border-radius: 5px; 
        box-shadow: 0 2px 6px rgba(0,0,0, .3);
        transition: all ease-in .2s;
        background-color: rgba(0,0,0, .1);

        &:hover { cursor: pointer; }
    }

    div { 
        width: 100%;
        padding-bottom: .5rem;
        vertical-align: top;
        display: inline-block;
        text-align: center;
    }
}

.mosaic img:hover, .articleBox:hover { 
    filter: brightness(100%); 
    box-shadow: 0 2px 6px rgba(0,0,0, .5); 
}



/************************** MODAL */

.modalShade {
    position: fixed; 
    height: 100vh;          
    top: 0; 
    background-color: rgba(0,0,0, .8);
    width: 100%;
    text-align: center;
    cursor: pointer;
}

.modal {
    position: fixed;
    border-radius: 5px;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    max-height: 95vh;
    background-color: rgba(0,0,0, .8);
    cursor: default;
    box-shadow: 0 4px 12px rgba(0,0,0, .7); 
    
    img { 
        border-radius: 5px 5px 0 0; 
        max-height: 80vh;
    }

}



/************************** WRITING */

.articles { columns: 3 300px; column-gap: 1rem; margin-top: 2rem; }

.articleBox { 
    padding: 0; 
    margin-bottom: 1rem; 
    filter: brightness(90%); 
    transition: all ease-in .2s; 
    box-shadow: 0 2px 6px rgba(0,0,0, .3);

    h3, div { padding: .5rem 1rem; }

    h3, a { text-align: center; display: block; }

}

.articleImg { 
    height: 200px; 
    background-size: cover; 
    border-radius: 5px 5px 0 0;
    background-position: center; 
}



/************************** CONTACT */

textarea { width: 100%; }


input, textarea, input[type="submit"] { 
    border-radius: 5px; 
    padding: 10px; 
    font-size: 1.1rem;
    border: none;
    background-color: rgba(255,255,255, .8);
    display: inline-block;
    max-width: 700px;
}

input { width: 290px; }

input[type="submit"] { 
    width: auto;
    background-color: $pink; 
    color: #fff;
    font-size: 18px; 
    padding: 10px 30px;
    cursor: pointer;
    opacity: .8;
    transition: all ease-in .2s;
    text-shadow: 0 2px 6px rgba(0,0,0, .5);
    
    &:hover { 
        opacity: 1; 
        box-shadow: 0 2px 6px rgba(0,0,0, .3); 
    }
}



/************************** 1200 BREAKPT (MINOR) */

@media only screen and (max-width: 1200px) {

    .desktopHide { display: block; }

    .nav h2 { font-size: 1.8rem; line-height: 1.9rem; }

}



/************************** 768 BREAKPT (MAJOR) */

@media only screen and (max-width: 768px) {

    body { overflow-x: hidden; }

    .desktopOnly { display: none; }

    .nav { 
        height: auto; 
        width: 100%; 
        padding: 5%; 
        z-index: 2; 

        h2 { 
            display: inline-block; 
            margin: auto; 
            font-size: 2rem; 
        }

        li { 
            font-size: 1.4rem; 
            line-height: 1.6rem; 
        }

        ul { 
            margin: 10px; transition: all ease-in .05s; 
        }

        ul.mobileHide {
            height: 0; 
            overflow: hidden; 
            margin: 0;
        }
    }

    .hamburger { display: inline-block; margin: 20px; font-size: 1.5rem;  }

    .container { width: 100%; margin: 0; }

    .pageBg { padding: 4%; padding-top: 170px; }

    h1 { 
        text-align: center; 
        display: block; 
        font-size: 3rem; 
        margin-bottom: 0; 
    }

    p, .webBox, .articleBox, .col-1-3, .col-2-3 { 
        font-size: 1rem; 
        line-height: 1.5rem; 
    }

    .mosaic img { filter: brightness(100%); }

    .col-1-3, .col-2-3 { width: 100%; }

    .flexCtr { display: block; }

    .form, .form * { width: 100%; margin: 0 !important; }

    .social a { display: inline-block; }

    .rightScroll { 
        white-space: normal;
        padding: 0;
        border-radius: 0;
        box-shadow: none;
        margin-top: 2rem;
    }

    .webBox {
        max-width: 100%;
        padding: 20px;
        filter: brightness(100%);
        white-space: normal;
        margin: 0;
        margin-bottom: 20px;
        vertical-align: top;
        min-height: auto;

        a { position: static; }
    }

    .webBoxImg { height: 170px; }

}